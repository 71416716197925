import React, { useContext } from "react";
import TextForm from "./TextForm";
import CheckboxForm from "./CheckboxForm";
import ButtonForm from "./ButtonForm";
import { EditorContext } from "../../contexts/EditorContext";
import { AppContext } from "../../contexts/AppContext";
import DownloadButton from "./DownloadButton";
import "./signEditor.css";
import { ButtonGroup } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import RadioButtons from "./RadioButtons";

const SignEditor = () => {
  const {
    changeSignName,
    changeSignPrice,
    changeAlergen,
    incrementBigName,
    decrementBigName,
    incrementBigPrice,
    decrementBigPrice,
    incrementSmallName,
    incrementSmallPrice,
    decrementSmallName,
    decrementSmallPrice,
    handleFontWeight,
  } = useContext(EditorContext);

  const { sign } = useContext(AppContext);

  const enabledAlergens = sign.alergens.filter((alergen) => alergen.contains);

  return (
    <div id="signEditor">
      <form>
        <div className="formGroup">
          <h2 className="formTitle">Nombre del plato</h2>
          <TextForm value={sign.name} handleOnChange={changeSignName} />
        </div>
        <div className="formGroup">
          <h2 className="formTitle mt">Precio</h2>
          <TextForm value={sign.price} handleOnChange={changeSignPrice} />
        </div>
        <div className="formGroup">
          <h2 className="formTitle mt">Alergenos</h2>
          <div className="multipleInputBox">
            {sign.alergens.map((alergen) => (
              <CheckboxForm
                key={alergen.name}
                alergen={alergen}
                handleOnChange={changeAlergen}
                disabled={enabledAlergens.length > 6}
              />
            ))}
          </div>
        </div>

        <div className="formGroup">
          <h2 className="formTitle mt">Tamaño fuente</h2>
          <div className="radioButtons">
            <RadioButtons onChange={handleFontWeight} />
          </div>
        </div>

        <div className="formGroup">
          <h2 className="formTitle mt">Cartel Grande</h2>
          <div className="formButtonGroup">
            <div className="buttonGroup">
              <h4>Tamaño Nombre</h4>
              <ButtonGroup>
                <ButtonForm
                  value={<AddIcon />}
                  handleClick={incrementBigName}
                />

                <ButtonForm
                  value={<RemoveIcon />}
                  handleClick={decrementBigName}
                />
              </ButtonGroup>
            </div>
            <div className="buttonGroup">
              <h4>Tamaño Precio</h4>
              <ButtonGroup>
                <ButtonForm
                  value={<AddIcon />}
                  handleClick={incrementBigPrice}
                />
                <ButtonForm
                  value={<RemoveIcon />}
                  handleClick={decrementBigPrice}
                />
              </ButtonGroup>
            </div>
          </div>
        </div>

        <div className="formGroup">
          <h2 className="formTitle mt">Cartel Pequeño</h2>
          <div className="formButtonGroup">
            <div className="buttonGroup">
              <h4>Tamaño Nombre</h4>
              <ButtonGroup>
                <ButtonForm
                  value={<AddIcon />}
                  handleClick={incrementSmallName}
                />

                <ButtonForm
                  value={<RemoveIcon />}
                  handleClick={decrementSmallName}
                />
              </ButtonGroup>
            </div>
            <div className="buttonGroup">
              <h4>Tamaño Precio</h4>
              <ButtonGroup>
                <ButtonForm
                  value={<AddIcon />}
                  handleClick={incrementSmallPrice}
                />
                <ButtonForm
                  value={<RemoveIcon />}
                  handleClick={decrementSmallPrice}
                />
              </ButtonGroup>
            </div>
          </div>
        </div>
        <div className="formGroup">
          <h2 className="formTitle mt">Descargar</h2>
          <DownloadButton elementId="big" value="Grande" />
          <DownloadButton elementId="small" value="Pequeño" />
        </div>
      </form>
    </div>
  );
};

export default SignEditor;
