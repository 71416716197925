import React, { useContext } from "react";
import "./signs.css";
import { AppContext } from "../../contexts/AppContext";
import AlergenList from "./AlergenList";

const BigSign = () => {
  const { sign, fontSizes } = useContext(AppContext);
  return (
    <main id="big" style={{ padding: "1px" }}>
      <div id="bigCard" className="card">
        <div id="bigCardName">
          <pre
            style={{
              fontSize: `${fontSizes.bigName}cm`,
              fontWeight: fontSizes.weight,
            }}
          >
            {sign.name}
          </pre>
        </div>
        <div id="bigCardAlergens">
          <AlergenList size="big" />
        </div>
        <div id="bigCardPrice">
          <p
            style={{
              fontSize: `${fontSizes.bigPrice}cm`,
              fontWeight: fontSizes.weight,
            }}
          >
            {sign.price}
          </p>
        </div>
        <div id="bigSeparator"></div>
      </div>
    </main>
  );
};

export default BigSign;
