import React, { useContext } from "react";
import "./signs.css";
import { AppContext } from "../../contexts/AppContext";
import AlergenList from "./AlergenList";

const SmallSign = () => {
  const { sign, fontSizes } = useContext(AppContext);

  return (
    <main id="small" style={{ padding: "1px" }}>
      <div id="smallCard" className="card">
        <div id="smallCardName">
          <pre
            style={{
              fontSize: `${fontSizes.smallName}cm`,
              fontWeight: fontSizes.weight,
            }}
          >
            {sign.name}
          </pre>
        </div>
        <div id="smallCardAlergens">
          <AlergenList size="small" />
        </div>
        <div id="smallCardPrice">
          <p
            style={{
              fontSize: `${fontSizes.smallPrice}cm`,
              fontWeight: fontSizes.weight,
            }}
          >
            {sign.price}
          </p>
        </div>
        <div id="smallSeparator"></div>
      </div>
    </main>
  );
};

export default SmallSign;
