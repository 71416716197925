import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { GetApp } from "@material-ui/icons";
import { AppContext } from "../../contexts/AppContext";
import { saveAsPng } from "save-html-as-image";

const DownloadButton = ({ elementId, value }) => {
  const { sign } = useContext(AppContext);

  const handleOnClick = (e) => {
    e.preventDefault();
    saveAsPng(document.getElementById(elementId), { filename: sign.name, forceFixText: false });
  };
  return (
    <Button
      variant="contained"
      color="secondary"
      startIcon={<GetApp />}
      onClick={handleOnClick}
    >
      {value}
    </Button>
  );
};

export default DownloadButton;
