import React from "react";

const CheckboxForm = ({alergen, handleOnChange, disabled}) => {


  return (
    <div className="multipleInput">
      <label key={alergen.name}>
        <input
          type="checkbox"
          checked={alergen.contains}
          value={alergen.name}
          onChange={handleOnChange}
          disabled={disabled && !alergen.contains}
        ></input>
        {alergen.name}
      </label>
    </div>
  );
};

export default CheckboxForm;
