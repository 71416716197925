import React from "react";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";

const RadioButtons = ({onChange}) => {

  return (
    <FormControl component="fieldset">
      <RadioGroup
        onChange={onChange}
        row
        aria-label="position"
        name="font-weight"
        defaultValue="top"
      >
        <FormControlLabel
          style={{ width: "auto" }}
          value="400"
          control={<Radio color="primary" />}
          label="Normal"
          labelPlacement="top"
        />
        <FormControlLabel
          value="700"
          control={<Radio color="primary" />}
          label="Gruesa"
          labelPlacement="top"
        />
        <FormControlLabel
          value="900"
          control={<Radio color="primary" />}
          label="Enorme"
          labelPlacement="top"
        />
      </RadioGroup>
    </FormControl>
  );
};

export default RadioButtons;
